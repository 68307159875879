<template>
  <div class="send-itinerary-update">
    <div class="send-itinerary-update--content">
      <CRForm ref="form">
        <div>
          <v-layout row class="send-itinerary-update--email-row">
            <v-flex xs12>
              <CRTextArea
                v-model="comments"
                class="send-itinerary-update--input"
                placeholder="Write itinerary update details for the provider here."
                :rules="commentsRules"
                background-color="inputBackgroundGray"
                validate-on-blur
              />
            </v-flex>
          </v-layout>
        </div>
      </CRForm>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-itinerary-update-send-button"
      class="cr-sidebar-dialog--action-btn"
      @click="send"
    >
      <span v-if="!loading">Send</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>
<script>
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    tripId: {
      type: Number,
      required: true,
    },
    referredTo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      comments: '',
      commentsRules: [
        isRequired(true, isNotEmpty, {
          req: 'A comment is required to send this mail',
          error: 'A comment is required to send this mail',
        }),
      ],
      loading: false,
    }
  },
  computed: {
    referralReservations() {
      let referralReservations = []
      for (const referral of this.referredTo) {
        if (referral.referralStatus != 'rejected') {
          referralReservations.push(referral.reservationId)
        }
      }
      return referralReservations
    },
  },
  methods: {
    async send() {
      this.loading = true

      const formIsValid = this.$refs['form'].validate()
      if (!formIsValid) {
        this.loading = false
        return
      }

      const payload = {
        tripId: this.tripId,
        comments: this.comments,
      }

      await this.$store.dispatch('trips/sendItineraryUpdateAndPostComment', {
        tripId: this.tripId,
        referralReservations: this.referralReservations,
        payload,
      })

      this.loading = false
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.send-itinerary-update {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--email-row {
    margin: 10px 0;
  }

  &--link-btn {
    color: $primary;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
